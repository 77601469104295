
import { reactive, defineComponent, ref, onMounted, computed, onBeforeMount } from "vue";
import { useI18n } from "vue-i18n";
import moment from 'moment';

import { translate } from "@/core/helpers/translate";
import {
  useQuery,
} from "@/core/helpers/common-helper";
import {
  showErrorPopup,
  showSuccessPopup,
  inputNumbersOnly
} from "@/core/helpers/common-helper";
import ApiService from "@/core/services/ApiService";
import { numberFormat } from "@/core/helpers/currency-helper";
import { useRouter } from "vue-router";
import useTransferType from "@/core/services/compositions/enum/useTransferType";
import useInstitution from "@/core/services/compositions/enum/useInstitution";
import useChannelByHash from "@/core/services/compositions/enum/useChannelByHash";
import { checkPermission } from "@/router/authentication";

interface TransferOnline {
  source_account: string;
  source_account_holder: string;
  destination_account: string;
  destination_account_holder: string;
  amount?: number;
  description: string;
  transfer_type: string;
  institution: string;
  channel: string;
  bank_code: string;
  va_billing_info: string;
  beneficiary_customer_residence: string;
  beneficiary_customer_type: string;
  beneficiary_address: string;
  beneficiary_email: string;
  beneficiary_id_card: string;
  sender_customer_residence: string;
  sender_customer_type: string;
  sender_address: string;
  sender_postal_code: string;
  sender_id_card: string;
  beneficiary_phone: string;
  sender_city_code: string;
  beneficiary_city_code: string;
}

export default defineComponent({
  setup() {
    // init
    const { t } = useI18n();
    const submitButton = ref<null | HTMLButtonElement>(null);
    const dialogVisible = ref(false);
    const dialogOTPVisible = ref(false);
    const isValidatingSource = ref(false);
    const isValidatingDestination = ref(false);
    const isValidatingBankCodeOnline = ref(false);
    const isValidatingBankCodeSknRtgs = ref(false);
    const isValidatingRegional = ref(false);
    const isValidatingCity = ref(false);
    const { query } = useQuery();
    const destinationAccount = ref();
    const sourceAccount = ref();
    const bankCodeOnline = ref();
    const bankCodeSknRtgs = ref();
    const regionals = ref();
    const cities = ref();
    const descriptionDestinationAccount = ref();
    const descriptionSourceAccount = ref();
    const router = useRouter();
    const inquiryInProcess = ref(false);
    const otp1 = ref('');
    const otp2 = ref('');
    const otp3 = ref('');
    const otp4 = ref('');
    const transferCode = ref('');
    const loadingOtp = ref(false)

    const queryParam = reactive({
      search: ''
    });

    const fetchParams = computed(() => ({
      name: queryParam.search,
      order_by: 'created_at',
      order: 'DESC',
      ts: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
    }));

    const listOfBank = ref([
      { 
        code: 'bca',
        name: 'BCA'
      },
      { 
        code: 'bri',
        name: 'BRI'
      },
    ]);

    const listOfResidence = ref([
      { 
        code: '1',
        name: 'Indonesia'
      },
      { 
        code: '2',
        name: 'Non Indonesia'
      },
    ]);

    const listOfCustomerType = ref([
      { 
        code: '1',
        name: 'Individual'
      },
      { 
        code: '2',
        name: 'Corporation'
      },
      { 
        code: '3',
        name: 'Government'
      },
    ]);
    
    const { transferType, isValidating: isValidatingType } = useTransferType();
    const { institution, isValidating: isValidatingInstitution } = useInstitution(() => fetchParams.value);
    const { channel, isValidating: isValidatingChannel } = useChannelByHash();

    const transfer = reactive<TransferOnline>({
      source_account: '',
      bank_code: '',
      source_account_holder: '',
      destination_account: '',
      destination_account_holder: '',
      description: '',
      institution: '',
      channel: '',
      transfer_type: '',
      va_billing_info: '',
      beneficiary_customer_residence: '',
      beneficiary_customer_type: '',
      beneficiary_address: '',
      beneficiary_email: '',
      beneficiary_id_card: '',
      sender_customer_residence: '',
      sender_customer_type: '',
      sender_address: '',
      sender_postal_code: '',
      sender_id_card: '',
      beneficiary_phone: '',
      sender_city_code: '',
      beneficiary_city_code: '',
    });

    const bankInfo = computed(() => {
      if (transfer.transfer_type == 'online') {
        return bankCodeOnline.value.find(v => {
          return v.bank_code == transfer.bank_code
        })
      }
      return bankCodeSknRtgs.value.find((v) => {
        return v.bank_code == transfer.bank_code
      })
    })

    const beneficiaryResidenceInfo = computed(() => {
      return listOfResidence.value.find((v) => {
        return v.code == transfer.beneficiary_customer_residence
      })
    })

    const beneficiaryTypeInfo = computed(() => {
      return listOfCustomerType.value.find((v) => {
        return v.code == transfer.beneficiary_customer_type
      })
    })

    const beneficiaryCity = computed(() => {
      return cities.value.find((v) => {
        return v.code == transfer.beneficiary_city_code
      })
    })

    const senderResidenceInfo = computed(() => {
      return listOfResidence.value.find((v) => {
        return v.code == transfer.sender_customer_residence
      })
    })

    const senderTypeInfo = computed(() => {
      return listOfCustomerType.value.find((v) => {
        return v.code == transfer.sender_customer_type
      })
    })

    const senderCity = computed(() => {
      return cities.value.find((v) => {
        return v.code == transfer.sender_city_code
      })
    })

    const filteredType = computed(() => {
      if (transferType.value) {
        delete transferType.value['overbooking']
        delete transferType.value['va']
      }

      return transferType.value      
    })

    const showDialog = () => {
      if (transfer.source_account == null || transfer.source_account == '') {
        showErrorPopup({
          title: 'Error',
          text: '[Source Account Number] cannot empty',
          message: '[Source Account Number] cannot empty',
        })

        return;
      }

      if (transfer.bank_code == null) {
        showErrorPopup({
          title: 'Error',
          text: '[Bank Destination] cannot empty',
          message: '[Bank Destination] cannot empty',
        })

        return;
      }

      if (transfer.destination_account == null) {
        showErrorPopup({
          title: 'Error',
          text: '[Destination Account Number] cannot empty',
          message: '[Destination Account Number] cannot empty',
        })

        return;
      }

      if (transfer.amount == null) {
        showErrorPopup({
          title: 'Error',
          text: '[Amount] cannot empty',
          message: '[Amount] cannot empty',
        })

        return;
      }

      dialogVisible.value = true;

      if (transfer.transfer_type == 'va') {
        inquiryInProcess.value = true;

        const payload = {
          account_number: transfer.destination_account,
        };

        ApiService.setHeader();
        ApiService.post(
          `app/finance/account/inquiry`,
          payload as any
        )
          .then((response) => {
            transfer.destination_account_holder = response.data.data.acctName;
            transfer.va_billing_info = response.data.data.vaBillingInfo;
          })
          .catch(({ response }) => {

            if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
              if (response.data.data.length > 0) {
                for (const key in response.data.data) {
                  showErrorPopup({
                    title: response.data.rc,
                    text: response.data.data[key],
                    message: response.data.data[key],
                  })
                }
              } else {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.message,
                  message: response.data.message,
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }

            dialogVisible.value = true;
          })
          .finally(() => {
            submitButton.value ? (submitButton.value.disabled = false) : null;
            submitButton.value?.removeAttribute("data-kt-indicator");

            inquiryInProcess.value = false;
          });
      }
    }

    const getSourceAccount = (channel: string) => {
      isValidatingSource.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/channel-pool-account`,
        "",
        {
          params: {
            identification_id: channel,
          }
        }
      )
        .then((response) => {
          if (response.data.data.length > 0) {
            ApiService.getWithoutSlug(
              `app/enum/account`,
              "",
              {
                params: {
                  account_numbers: response.data.data,
                  type: 'internal'
                }
              }
            )
              .then((resp) => {
                sourceAccount.value = resp.data.data;
              })
              .finally(() => {
                isValidatingSource.value = false;
              });
          }
        })
        .catch(() => {
          isValidatingSource.value = false;
        });
    };

    const getDestinationAccount = (id?: string) => {
      isValidatingDestination.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/account`,
        "",
        {
          params: {
            type: 'institution',
            identification_id: id ?? ''
          }
        }
      )
        .then((resp) => {
          destinationAccount.value = resp.data.data;
        })
        .finally(() => {
          isValidatingDestination.value = false;
        });
    };

    const getBankCodeOnline = () => {
      isValidatingBankCodeOnline.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/bank-code-online`)
        .then((resp) => {
          bankCodeOnline.value = resp.data.data
        })
        .finally(() => {
          isValidatingBankCodeOnline.value = false;
        });
    };

    const getBankCodeSknRtgs = () => {
      isValidatingBankCodeSknRtgs.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/bank-code-skn-rtgs`)
        .then((resp) => {
          bankCodeSknRtgs.value = resp.data.data
        })
        .finally(() => {
          isValidatingBankCodeSknRtgs.value = false;
        });
    };

    const getRegional = () => {
      isValidatingRegional.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/regional-skn-rtgs`)
        .then((resp) => {
          regionals.value = resp.data.data
        })
        .finally(() => {
          isValidatingRegional.value = false;
        });
    };

    const getCities = () => {
      isValidatingCity.value = true;

      ApiService.setHeader();
      ApiService.getWithoutSlug(
        `app/enum/city-skn-rtgs`)
        .then((resp) => {
          cities.value = resp.data.data
        })
        .finally(() => {
          isValidatingCity.value = false;
        });
    };

    const checkOtp = () => {
      loadingOtp.value = true

      const payload = {
        source_account_no: transfer.source_account,
        beneficiary_account_no: transfer.destination_account,
        amount: transfer.amount,
        description: transfer.description,
        type: transfer.transfer_type,
        va_billing_info: transfer.va_billing_info,
        beneficiary_account_name: transfer.destination_account_holder,
        beneficiary_bank_code: transfer.bank_code,
        beneficiary_bank_name: transfer.transfer_type == 'online' ? bankCodeOnline.value.find(v => {
          return v.bank_code == transfer.bank_code
        })?.bank_name : bankCodeSknRtgs.value.find(v => {
          return v.bank_code == transfer.bank_code
        })?.bank_name,
        channel: transfer.channel,
        institution: transfer.institution
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/transfer-online/maker`,
        payload as any
      )
        .then((resp) => {
          transferCode.value = resp.data.data.transfer_code
          dialogVisible.value = false;
          dialogOTPVisible.value = true;
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          loadingOtp.value = false

          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    }

    const submitOverbooking = () => {
      dialogVisible.value = false;
      dialogOTPVisible.value = false;

      submitButton.value ? (submitButton.value.disabled = true) : null;
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      const payload = {
        source_account_no: transfer.source_account,
        beneficiary_account_no: transfer.destination_account,
        amount: transfer.amount,
        description: transfer.description,
        type: transfer.transfer_type,
        va_billing_info: transfer.va_billing_info,
        beneficiary_account_name: transfer.destination_account_holder,
        beneficiary_bank_code: transfer.bank_code,
        beneficiary_bank_name: transfer.transfer_type == 'online' ? bankCodeOnline.value.find(v => {
          return v.bank_code == transfer.bank_code
        })?.bank_name : bankCodeSknRtgs.value.find(v => {
          return v.bank_code == transfer.bank_code
        })?.bank_name,
        transfer_code: transferCode.value,
        institution: transfer.institution,
        channel: transfer.channel,
        otp: `${otp1.value}${otp2.value}${otp3.value}${otp4.value}`
      };

      ApiService.setHeader();
      ApiService.post(
        `app/finance/transfer-online/maker`,
        payload as any
      )
        .then(() => {
          showSuccessPopup({
            title: 'Transfer Online',
            text: 'Waiting Approver to proceed transfer'
          });

          transfer.source_account = '';
          transfer.destination_account = '';
          transfer.description = '';
          transfer.transfer_type = '';
          transfer.va_billing_info = '';

          setTimeout(() => {
            router.push({ name: "app.finance.online.request" });
          }, 1700)
        })
        .catch(({ response }) => {
          if (Object.prototype.hasOwnProperty.call(response.data, "data")) {
            if (response.data.data.length > 0) {
              for (const key in response.data.data) {
                showErrorPopup({
                  title: response.data.rc,
                  text: response.data.data[key],
                  message: response.data.data[key],
                })
              }
            } else {
              showErrorPopup({
                title: response.data.rc,
                text: response.data.message,
                message: response.data.message,
              })
            }
          } else {
            showErrorPopup({
              title: response.data.rc,
              text: response.data.message,
              message: response.data.message,
            })
          }
        })
        .finally(() => {
          submitButton.value ? (submitButton.value.disabled = false) : null;
          submitButton.value?.removeAttribute("data-kt-indicator");
        });
    };

    const searchInstitutionAccount = (v) => {
      getDestinationAccount(v);
    }

    const gotoInquiry = () => {
      router.push({ name: "app.finance.account.inquiry" });
    };

    const changeSourceAccount = (val) => {
      const result = sourceAccount.value.filter(data => data.account_number == val);

      if (result.length > 0) {
        descriptionSourceAccount.value = result[0].description;
        transfer.source_account_holder = result[0].account_holder;
      }
    }

    const changeDestinationAccount = (val) => {
      const result = destinationAccount.value.filter(data => data.account_number == val);

      if (result.length > 0) {
        descriptionDestinationAccount.value = result[0].description
        transfer.destination_account_holder = result[0].account_holder
      }
    }

    const searchInstitution = (val) => {
      queryParam.search = val;
    }

    const transferTypeChange = (val) => {
      transfer.bank_code = ''
    }

    const resetOtp = () => {
      otp1.value = ''
      otp2.value = ''
      otp3.value = ''
      otp4.value = ''
    }

    const terbilang = (a) => {
      const bilangan = ['', 'Satu', 'Dua', 'Tiga', 'Empat', 'Lima', 'Enam', 'Tujuh', 'Delapan', 'Sembilan', 'Sepuluh', 'Sebelas'];
      let kalimat = '';

      // 1 - 11
      if (a < 12) {
        kalimat = bilangan[a];
      }
      // 12 - 19
      else if (a < 20) {
        kalimat = bilangan[a - 10] + ' Belas';
      }
      // 20 - 99
      else if (a < 100) {
        const utama = a / 10;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10;
        kalimat = bilangan[depan] + ' Puluh ' + bilangan[belakang];
      }
      // 100 - 199
      else if (a < 200) {
        kalimat = 'Seratus ' + terbilang(a - 100);
      }
      // 200 - 999
      else if (a < 1000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 100;
        kalimat = bilangan[depan] + ' Ratus ' + terbilang(belakang);
      }
      // 1,000 - 1,999
      else if (a < 2000) {
        kalimat = 'Seribu ' + terbilang(a - 1000);
      }
      // 2,000 - 9,999
      else if (a < 10000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000;
        kalimat = bilangan[depan] + ' Ribu ' + terbilang(belakang);
      }
      // 10,000 - 99,999
      else if (a < 100000) {
        const utama = a / 100;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 100,000 - 999,999
      else if (a < 1000000) {
        const utama = a / 1000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000;
        kalimat = terbilang(depan) + ' Ribu ' + terbilang(belakang);
      }
      // 1,000,000 - 	99,999,999
      else if (a < 100000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 1000000000) {
        const utama = a / 1000000;
        const depan = parseInt(String(utama).substr(0, 4));
        const belakang = a % 1000000;
        kalimat = terbilang(depan) + ' Juta ' + terbilang(belakang);
      }
      else if (a < 10000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 100000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 1000000000000) {
        const utama = a / 1000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000;
        kalimat = terbilang(depan) + ' Milyar ' + terbilang(belakang);
      }
      else if (a < 10000000000000) {
        const utama = a / 10000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 10000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }
      else if (a < 100000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 2));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 1000000000000000) {
        const utama = a / 1000000000000;
        const depan = parseInt(String(utama).substr(0, 3));
        const belakang = a % 1000000000000;
        kalimat = terbilang(depan) + ' Triliun ' + terbilang(belakang);
      }

      else if (a < 10000000000000000) {
        const utama = a / 1000000000000000;
        const depan = parseInt(String(utama).substr(0, 1));
        const belakang = a % 1000000000000000;
        kalimat = terbilang(depan) + ' Kuadriliun ' + terbilang(belakang);
      }

      const pisah = kalimat.split(' ');
      const full = [''];
      for (let i = 0; i < pisah.length; i++) {
        if (pisah[i] != "") { full.push(pisah[i]); }
      }
      return full.join(' ');
    }

    onBeforeMount(() => {
      if (!checkPermission('transfer-online-maker').isGranted) {
        router.push({ name: "app" });
      }
    })

    onMounted(() => {
      getBankCodeOnline();
      getBankCodeSknRtgs();
      getRegional();
      getCities();
    })

    return {
      translate,
      transfer,
      dialogVisible,
      dialogOTPVisible,
      submitButton,
      showDialog,
      submitOverbooking,
      checkOtp,
      inputNumbersOnly,
      numberFormat,
      destinationAccount,
      sourceAccount,
      terbilang,
      gotoInquiry,
      isValidatingDestination,
      isValidatingSource,
      descriptionDestinationAccount,
      descriptionSourceAccount,
      changeSourceAccount,
      changeDestinationAccount,
      transferType,
      inquiryInProcess,
      isValidatingType,
      otp1,
      otp2,
      otp3,
      otp4,
      listOfBank,
      bankInfo,
      listOfResidence,
      listOfCustomerType,
      beneficiaryResidenceInfo,
      beneficiaryTypeInfo,
      bankCodeOnline,
      bankCodeSknRtgs,
      beneficiaryCity,
      senderResidenceInfo,
      senderTypeInfo,
      senderCity,
      regionals,
      cities,
      isValidatingBankCodeOnline,
      isValidatingBankCodeSknRtgs,
      isValidatingRegional,
      isValidatingCity,
      transferTypeChange,
      institution,
      isValidatingInstitution,
      searchInstitution,
      channel,
      isValidatingChannel,
      loadingOtp,
      resetOtp,
      filteredType,
      searchInstitutionAccount,
      getSourceAccount,
    };
  }
});
